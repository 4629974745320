

















import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import app from '@/store/modules/app'
import { openExternalLinkInNewTab } from '@/utils/router'
import UIMap from '@/components/UIMap.vue'
import { Marker } from '@/models/Marker'
import DetailsEvents from '@/components/DetailsEvents.vue'
import DetailsInfo from '@/components/DetailsInfo.vue'

@Component({
  components: { Main, UIMap, DetailsEvents, DetailsInfo },
  metaInfo() {
    return {
      title: `${app.title} | Details`,
      meta: [
        {
          name: 'description',
          content: 'Details about the wedding, surrounding events, and more.',
        },
        {
          name: 'keywords',
          content:
            'Details, Venue, Event, Information, Wedding, Hotel, Lodging',
        },
        // Add Open Graph, Twitter, or any other meta tags here
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://themadays.com/details' },
        { property: 'og:title', content: 'Details - Katie & Erik' },
        {
          property: 'og:description',
          content: 'Details about the wedding, surrounding events, and more.',
        },
        // Twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: 'https://themadays.com/details' },
        { name: 'twitter:title', content: 'Details - Katie & Erik' },
        {
          name: 'twitter:description',
          content: 'Details about the wedding, surrounding events, and more.',
        },
      ],
      link: [{ rel: 'canonical', href: 'https://themadays.com/details' }],
    }
  },
})
export default class Details extends Vue {
  markers: Marker[] = [
    {
      lat: 33.9854459,
      lng: -85.0808119,
      popupHtml:
        '<h3 class="font-regular">Spring Lake Events</h3><p class="font-regular margin-t-0">445 Government Farm Rd</p><p class="font-regular margin-t-0">Rockmart, GA 30153</p>',
      color: this.$vuetify.theme.themes.light.greenDark.toString(),
    },
    {
      lat: 33.786473,
      lng: -84.385913,
      popupHtml:
        '<h3 class="font-regular">AC Hotel by Marriott Atlanta Midtown</h3><p class="font-regular margin-t-0">53 14th St NE</p><p class="font-regular margin-t-0">Atlanta, GA 30309</p>',
      color: this.$vuetify.theme.themes.light.greenDark.toString(),
    },
    {
      lat: 33.785815,
      lng: -84.385964,
      popupHtml:
        '<h3 class="font-regular">Moxy Atlanta Midtown</h3><p class="font-regular margin-t-0">48 13th St NE</p><p class="font-regular margin-t-0">Atlanta, GA 30309</p>',
      color: this.$vuetify.theme.themes.light.greenDark.toString(),
    },
  ]

  goToDirectionsToVenue(): void {
    openExternalLinkInNewTab(
      'https://www.google.com/maps/dir//Spring+Lake+Events+445+Government+Farm+Rd+Rockmart,+GA+30153/@33.985446,-85.0763272,12z/data=!4m5!4m4!1m0!1m2!1m1!1s0x888ac8c478df5cbd:0xfc67f30344fb13f3'
    )
  }
  goToDirectionsToACHotel(): void {
    openExternalLinkInNewTab(
      'https://www.google.com/maps/dir//Moxy+Atlanta+Midtown,+48+13th+St+NE,+Atlanta,+GA+30309/@33.7860914,-84.3864558,18.9z/data=!4m19!1m9!3m8!1s0x88f5045cab89fa87:0x2420675c604f10f!2sMoxy+Atlanta+Midtown!5m2!4m1!1i2!8m2!3d33.7858229!4d-84.3859649!4m8!1m0!1m5!1m1!1s0x88f5045cab89fa87:0x2420675c604f10f!2m2!1d-84.3859649!2d33.7858229!3e2'
    )
  }
  goToDirectionsToMoxy(): void {
    openExternalLinkInNewTab(
      'https://www.google.com/maps/dir//Moxy+Atlanta+Midtown,+48+13th+St+NE,+Atlanta,+GA+30309/@33.7860914,-84.3864558,18.9z/data=!4m19!1m9!3m8!1s0x88f5045cab89fa87:0x2420675c604f10f!2sMoxy+Atlanta+Midtown!5m2!4m1!1i2!8m2!3d33.7858229!4d-84.3859649!4m8!1m0!1m5!1m1!1s0x88f5045cab89fa87:0x2420675c604f10f!2m2!1d-84.3859649!2d33.7858229!3e2'
    )
  }
}
