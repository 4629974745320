





































import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class DetailsEvents extends Vue {
  events = [
    {
      date: 'Friday, September 29, 2023',
      events: [
        {
          time: '8:00 PM',
          title: 'Informal Get-Together at Scofflaw Brewing',
        },
      ],
    },
    {
      date: 'Saturday, September 30, 2023',
      events: [
        {
          time: '2:15 PM',
          title:
            'Board the bus (outside Moxy Atlanta Midtown/AC Hotel Atlanta Midtown) if you have RSVPed for this transportation option',
        },
        {
          time: '2:30 PM',
          title:
            'Buses leave Moxy Atlanta Midtown and AC Hotel Atlanta Midtown',
        },
        {
          time: '4:00 PM',
          title:
            'Wedding Ceremony at Spring Lake Events (please arrive 15 minutes early if driving)',
        },
        {
          time: '4:45 PM',
          title: 'Cocktail hour',
        },
        {
          time: '6:00 PM',
          title: 'Dinner',
        },
        {
          time: '7:00 PM',
          title: 'Drinks and dancing',
        },
        {
          time: '10:30 PM',
          title: 'Buses leave Spring Lake Events',
        },
        {
          time: '11:45 PM',
          title: 'Buses arrive in Atlanta',
        },
      ],
    },
    {
      date: 'Sunday, October 1, 2023',
      events: [
        {
          time: '10:00 AM',
          title:
            'Complimentary breakfast provided at Moxy Atlanta Midtown and AC Hotel Atlanta Midtown',
        },
      ],
    },
  ]
}
