




import { Vue, Component, Prop } from 'vue-property-decorator'
import mapboxgl from 'mapbox-gl'
import { Marker } from '@/models/Marker'

@Component({})
export default class UIMap extends Vue {
  @Prop({ type: Array, required: false, default: null })
  readonly center: number[]
  @Prop({ type: Array, required: false, default: () => [] })
  readonly markers: Marker[]

  accessToken: string =
    process.env.VUE_APP_MAPBOX_API_KEY ||
    'pk.eyJ1IjoiZXJpa21hZGF5IiwiYSI6ImNsYndnY29ibjBjcGczb21sZ2dtMWJucG0ifQ.Bs9xtWaFKjLwksKM1MknUw'
  map = null

  mounted(): void {
    mapboxgl.accessToken = this.accessToken
    const options: any = {
      container: 'mapContainer',
      style: 'mapbox://styles/erikmaday/clbwh52sr000a14s4vqfo3qko',
      zoom: 10,
    }
    if (this.center) {
      options.center = this.center
    }
    this.map = new mapboxgl.Map(options)

    if (this.markers.length) {
      for (const marker of this.markers) {
        this.addMarker(marker)
      }
      if (!this.center) {
        this.map.fitBounds(this.getBoundingCoordinates(this.markers), {
          padding: 50,
          linear: true,
        })
      }
    }
  }

  addMarker(marker: Marker): void {
    const options: any = {}
    if (marker.color) {
      options.color = marker.color
    }
    const mbMarker = new mapboxgl.Marker(options).setLngLat([
      marker.lng,
      marker.lat,
    ])
    if (marker.popupHtml) {
      mbMarker.setPopup(new mapboxgl.Popup().setHTML(marker.popupHtml))
    }
    mbMarker.addTo(this.map)
  }

  public getBoundingCoordinates(markers: Marker[]): number[][] {
    let northeast = { lat: -Infinity, lng: -Infinity }
    let southwest = { lat: Infinity, lng: Infinity }

    for (const marker of markers) {
      northeast = {
        lat: Math.max(northeast.lat, marker.lat),
        lng: Math.max(northeast.lng, marker.lng),
      }
      southwest = {
        lat: Math.min(southwest.lat, marker.lat),
        lng: Math.min(southwest.lng, marker.lng),
      }
    }

    return [
      [northeast.lng, northeast.lat],
      [southwest.lng, southwest.lat],
    ]
  }
}
