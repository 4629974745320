


















































































import { Vue, Component } from 'vue-property-decorator'
import { openExternalLinkInNewTab } from '../utils/router'

@Component({})
export default class DetailsInfo extends Vue {
  goToHotels(): void {
    openExternalLinkInNewTab(
      'https://www.marriott.com/event-reservations/reservation-link.mi?id=1671471689217&key=GRP&app=resvlink'
    )
  }
}
